.layout-module--footer--1q4Vo {
    padding: 20px 1px 5px;
    background-color: #1b5552;
    color: azure;
    font-size: 11pt;
}

.layout-module--footerBody--2KFe- {
    max-width: 1240px;
    margin: 0 auto 5px;
}

.layout-module--logoFooter--3Qj7p {
    width: 130px;
    margin: 10px 0 10px 10px;
    padding: 20px 10px 15px 20px
}

.layout-module--logoFooter--3Qj7p img {
    width: 130px;
}

.layout-module--footerCol--9jru- {
    margin: -10px;
    padding: 20px 10px 15px 20px;
    color: #fff;
    max-width: 529px;
}

#layout-module--footerColtxt--LLGIb {
    margin: 0 0 15px -15px;
}

.footerForm {
    background-color: rgb(245, 241, 241);
}

.footer {
    background-color: #215552;
    height: fit-content;
    padding-top: 10vh;
    padding-bottom: 10vh;
    height: auto !important;
}


#instagram {
    padding: 3px;
    background-color: white;
    font-size: 1.5em;
    border-radius: 3px;
}

#linkedin {
    padding: 3px;
    background-color: white;
    font-size: 1.5em;
    border-radius: 3px;

}

.input-field {
    border: none;
    font-family: Lato;
    width: 220px;
    height: 30px;
    margin: 0 30px 10px 0;
    border-radius: 0;
    font-size: 16px;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Icon1 {
    width: 10px;

    FaLinkedinIn{
        color: white;
    }
}
.linked{
    color: white;
}
.insta{
    color: white;
}
.Icon1:hover {
    color: white;   
}

.Icon2 {
    width: 10px;
    BsInstagram{
        color: #5cba47;
    }
}

.Icon2:hover {
    color: white;
}
.layout-module--socialLinks--1oxQ0 {
    margin-left: 30px;
    float: right;
}

/* @media only screen and (max-width: 600px) and (min-width: 501px) */

.insta,
.linked {
    width: 25px;
}

.feed-form,
.feed-text {
    text-align: center;
}

.feed-text p {
    margin-left: 0;
}

@media (min-width:993px) {
    .footer {
        height: auto;

    }

    .feed-form,
    .feed-text {
        text-align: left;
    }
}
.input1 {
    border-radius: 0 !important;

}

.subscribe_button {
    border-radius: 0 !important;
    // width: 80%;
    width: 100%;
    height: 52px;
}

.footer-last {
    font-size: 0.8em;
    width: 70%;
    margin-top: 4em;
}

.subscribe-row {
    width: 69%;
    margin: 0 auto
}

.images {
    height: 65px;
}

.sub_email {
    position: relative;
}

.sub_now {
    width: 16vw;
    position: absolute;
    left: 101px;
    padding: 11px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    background-color: #5cba47;
}

.buttonfooter {
    height: 100%;
    background-color: #5cba47;
    color: white;
    width: auto;
    padding: 10px;
    margin-left: -10px;

}

.Sub_Email {
    border: none;
    padding-left: 2vh;
    height: 9vh;
    width: 100%;
}

.explore_footer {
    margin-left: 30px;
    margin-top: -9px;
}

.morethan {
    font-weight: 700;
    margin: 0;
    font-size: 19px;
    margin-left: 10px;
    text-align: left;
}

.footer-subscribe {
    background-color: #5cba47;
    width: unset !important;
    height: unset !important;
    padding: 3vh 2vh;
    color: #fff;

}
.footer-adderss{
    h5{
        font-size: 1.1rem !important;
    }
}
.footer-link{
    h5{
        font-size: 1.1rem !important;
    }
    p{
        margin-left: 2vh;
    }
}
.footer-social{
    h5{
        font-size: 1.1rem !important;
    }
}
.footer-donate{
    background-color: #5cba47;
    color: #fff;
    font-weight: 700;
    height: unset !important;
    width: unset !important;
    padding: 3vh 2vh;
    font-size: 0.9rem;
}
.copy-right{
    margin-top: 5vh !important;
    text-align: center;
    color: #fff;
}
.location_icoin{
    width: 4vw;
    padding-right: 6px;
}

.donate_us{
    text-decoration:none !important;
    color:#ffff !important;
    background-color: #5cba47;
    padding:3vh;
    font-weight: 600; 
} 
.footer_dntus{
    margin-top:40px;
    cursor: pointer;
}
.address{
    text-align: start;
}


@media(min-width:320px) and (max-width:768px){
    .footer{
        padding-top:5vh;
    }
    .feed-logo,.footer-adderss,.footer-link,.footer-social{
        text-align: center;
    }
    .copy-right{
        font-size: 0.8rem;
    }
    .Sub_Email{
      width: 80%;
    }
    .location_icoin{
        width: 18vw;
    }
    .map{
        margin-top: unset;
    }
}
.subscribe-btn{
    text-decoration: none;
    color: #fff;
}
.subscribe-btn:hover{
    text-decoration: none;
    color: #fff;
}