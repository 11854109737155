@use '../styles/colors';

b{
  font-weight: bolder;
}
.formFooter, .formFooter-wrapper {
  display: none !important;
}
.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  background-color: colors.$yellow !important;
  color: white;
  border: none;
  border-radius: 0;
  padding: 1px 1px 1px 3px !important;
}

.closeBtn:hover {
  opacity: 1;
  background-color: white;
  color: colors.$navy;
}

button {
  width: 110px;
  height: 40px;
  font-family: Lato;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 12px;
  right: 0;
  margin-right: 15px;
  color: colors.$light-navy;
  background-color: colors.$yellow;
  border: none;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

label {
  font-weight: 600;
  color: colors.$text-body-blue;
  font-size: 16.5px;
}

input, textarea, select{
  border-radius: 0;
}

.getInvolved{
padding: 0px;
}

.restHeader{
  height: 60px;
  background-color: colors.$navy;
  color: white;
  padding: 15px;
  position: fixed;
  top: 0px;
  width: 798px;
  z-index: 2000;
}
.restCloseBtn{
  position: absolute;
  right: 10px;
  top: 15px;
  width: 32px;
  height: 32px;
}
.restCloseBtn:hover {
  opacity: 0.5;
}
.restCloseBtn:before, .restCloseBtn:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 1px;
  background-color: colors.$yellow;
}
.restCloseBtn:before {
  transform: rotate(45deg);
}
.restCloseBtn:after {
  transform: rotate(-45deg);
}
.restName{
  text-align: center;
  width: 100%;
  margin: 0px auto;
  text-transform: uppercase;
}

.carousel{
  margin: 75px 0px 40px 0px;
}
.carousel div{
  max-height: 360px;
}
.carousel div ol li{
  border-radius: 100%;
  width: 7px;
  height: 7px;
  position: relative;
  background-color: #3b4f63;
  top: 70px;
}
.leftBlock p{
  font-size: 17px;
}

.infoblock{
  background-color: white;
  margin: -20px 10px 0px 10px;
  box-shadow: black;
  padding: 20px 10px;
}
.infoText{
  margin: 5px;
}

.quoteblock{
  max-width: 600px;
  margin: 20px auto 20px;
  padding: 10px;
}
.quote{
  color: colors.$bright-navy;
  text-align: center;
  margin: 10px;
}
.ownerName{
  text-align: right;
  color: #577491;
  font-size: 26px !important;
  font-family: 'Dancing Script', cursive !important;
}
.slide{
  max-height: 336px;
}

.sIcon{
  color: #fcc200;
  margin: 10px 10px 0px 0px;
}
.sIcon:hover{
  color: colors.$navy;
}
.map{
  max-width: 190px;
  height: 190px;
}
.leaflet-container {
  margin-top: 0px;
  height: 190px;
  width: 190px;
  position: initial;
}
.longBtn button{
  background-color: colors.$yellow;
  width: 100%;
  height: 80px;
  color: colors.$light-navy;
  border: none;
  font-family: Lato;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 16px;
  border-radius: 0;
}
.longBtn button:hover{
  background-color: colors.$btn-blue;
  color: colors.$light-navy;
}
.show{
  display: block;
}
.hidden{
  display: none;
}
@media (max-width: 991px) {
  .restHeader{
    height: 60px;
    width: 500px;
  }

}
@media (max-width: 576px) {
  .restHeader{
    width: 96%;
  }
  .donately-donation-form{
    border: 2px solid red;
  }

}

iframe .donately-secure-form-container{
  width: 100% !important;
}

#donation-form :target {
height: 1093px !important;
width: 422px !important;
background-color: red !important;
}