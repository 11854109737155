
strong{
    margin-top: -10px;
}
.button1{
    width: 100px;
}
.container{
    margin-top: 30px;
}
.main{
    margin-top: 30px;
    
}
.fa-regular{

    color: green;
}
.item{
    margin-top: -100px;
}
span{
    margin-top: -38px;
}
#sub{
    margin-top: -38px !important;
}