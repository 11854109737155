*,
:after,
:before {
    box-sizing: inherit;
}
.container {
    width: 100%;
}
.impact_date{
    display: flex;
    justify-content: center;
    margin-bottom:30px;
    align-items: center;
}
.date_color{
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.impact_data1{
    color: #ffff;
}
.date1{
    border: 2px solid white;
    width: auto;
    color: white;
    font-size: 19px;
    font-weight: 600;
    padding: 0.5vh;    
}
// Home component 

.Home_banner {
    background-image: url("https://cdn.pixabay.com/photo/2013/07/18/14/59/apples-164189_960_720.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 85vh;
    background-size: 100% 100%;
}
.header_button {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    background-color: olivedrab;
}
#btn1234 {
    position: relative;
    left: 45% !important;
    background-color: green;
    color: white;
    height: 100%;
    width: auto;
    padding: 10px;
}

.button-subscribe {
    padding: 3vh;
    height: unset !important;
    width: unset !important;
    color: white;
    background-color: #58ba47;
}

.slider_bullet {
    font-size: 18px;
}

.navy-colored-block_map {
    background-color: #272751;
    padding: -1px 0;
    margin-top: 0;
    padding-bottom: 50px !important;
}

.sub_head {
    font-weight: 900;
    font-size: 10px;
    color: #64566b;
    letter-spacing: 2px;
}

.map {
    margin-top:70px;
}

.col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

h1 {
    font-weight: 700;
    color: #203143;
}

.section-block {
    margin: 60px auto;
}

.icons {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0;
}

.icon-col {
    width: 320px;
    margin: 0 auto;
    text-align: center;
    padding: 0 40px;
}

.icon {
    max-width: 140px;
}

h6 {
    font-weight: 600;
    text-transform: uppercase;
    color: #3b4f63 !important;
    letter-spacing: 1.5px;
    line-height: 1.6;
    font-size: 15px;
    text-decoration: none !important;
}

.icon {
    max-width: 140px;
}
.width1240_map{
    max-width:1240px !important;
    margin:0 auto !important;
    padding:0 10px !important;
    margin-bottom: 50px !important;
}

.white-txtt {
    color: #fff !important;
    font-size: 18.5px !important;
    font-family: Lato !important;
    margin: 0 0 1.45rem !important;
    padding-top: 15px;
    padding-bottom: 20px;
}

.btn-custom {
    font-size: 12px !important;
}

.btn-extra-long {
    width: 310px !important;
    margin: 5px 10px 10px !important;
}

.btn-custom {
    background-color: #58ba47 !important;
    font-family: Lato;
    text-transform: uppercase !important;
    border-radius: 0;
    border: none;
    font-weight: 600 !important;
    font-size: 14px !important;
    width: 140px !important;
    min-height: 37px !important;
    letter-spacing: 1.6px !important;
    border-radius: 0 !important;
    border:none !important;
}

.btn-custom2{
    background-color: #474897 !important;
    font-family: Lato;
    text-transform: uppercase !important;
    border-radius: 0;
    border: none;
    font-weight: 600 !important;
    font-size: 14px !important;
    width: 140px !important;
    min-height: 37px !important;
    letter-spacing: 1.6px !important;
    border-radius: 0 !important;
    border:none !important;
}

.btn {
    display: inline-block !important;
    text-align: center !important;
    vertical-align: middle !important;
    user-select: none !important;
    line-height: 1.5 !important;
    padding: 0.375rem 0.75rem !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important
}

.btn-extra-long {
    width: 310px !important;
    margin: 5px 10px 10px !important;
}

@media (max-width: 575.98px) {
    .ast-above-header.ast-above-header-bar {
        display: none;
    }

    .btn-extra-long {
        width: 100% !important;
    }

    .fade {
        padding: 0 !important;
    }
}

//sponsor 
.allimg {
    display: flex;
    justify-content: space-evenly;
}

.img1 {
    margin-top: 30px;
    font-size: 60px;
}

.allimg2 {
    display: flex !important;
    justify-content: space-evenly;
}

.iframe {
    width: 100% !important;
}

.img24 {
    height: 60px;
    width: 60px;
}

.img246 {
    height: 130px;
    width: 130px;

}

.img2467 {
    height: 220px;
    width: 220px;
}

.img2467 {
    height: 110px;
    width: 110px;
    margin-top: -5px;
}

.img2468 {
    height: 210px;
    width: 210px;
    margin-top: -54px;
}

// new css
.Home_header {
    background-image: url("../../src/Feed-Components/assets/header.png");
    width: 100%;
    height: 100vh;
    background-size: 100% 100%;
    position: relative;
}

.hero-section {
    position: absolute;
    top: 39vh;
    left: 20vh;

    h2 {
        font-size: 7vh;
        color: #fff;
    }

    p {
        color: #fff;
        font-size: 2.5vh;
        font-weight: 500;
    }

    button {
        background-color: #5cba47;
        height: unset !important;
        width: unset !important;
        padding: 3vh !important;
        color: #fff !important;
    }
}


.i-div {
  position: relative;
  padding: 41.25 0 0 0%;
}
     #iframe_1{
           position:absolute !important;
           top: 0 !important;
           left: 0 !important;
           width: 100% !important;
           height: 100% !important;
           z-index: 0 !important; 
     }

.our-partners {
    display: flex;
    align-items: center;
    justify-content: center;
    // filter: grayscale(10);
    margin-top: 2vh;
}
.alldata{
    width: 100% !important;
    display: flex;
    justify-content: space-around !important;
    margin-top: 65px !important;
   flex-wrap: wrap;
}
.but_alldata1{
    display: flex;
    padding: 2vh;
    color: #fff;
    text-decoration: none !important;
    height: unset !important;
    background-color: #5cba47;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}
.alldata{
    display: flex;
    justify-content: space-evenly !important;
}
.but_alldata1{
    display: flex;
    padding: 2vh;
    color: #fff;
    text-decoration: none !important;
    height: unset !important;
    background-color: #5cba47;
    font-weight: 600;
    cursor: pointer;
}
.but_alldata2{
    display: flex;
    padding: 2vh;
    color: #fff;
    text-decoration: none !important;
    height: unset !important;
    background-color: #5cba47;
    font-weight: 600;
    cursor: pointer;
}
.but_alldata3{
    display: flex;
    padding: 2vh;
    color: #fff;
    text-decoration: none !important;
    height: unset !important;
    background-color: #5cba47;
    font-weight: 600;
    cursor: pointer;
}

.our-partners img {
    width: 60%;
}
.allbutton{
    display: inline !important;
    flex-direction: row !important;
    height: 100%;
}
.number_but{
    width: 100%;
    height: auto !important;
}
.main123 {
    width: 100%;
    margin-top: 10vh;
}
.mainsub h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #64566b;
}

.mainsub_header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 30px;
}

.white_color {
    color: #fff;
    font-weight: 650;
    margin-top: 17px;
}
::ng-deep .vp-center{
    display: unset !important;
}

.number_color {
    color: #fff;
    margin-top: 17px;
}

.white-txt_header {
    font-weight: 500;
    color: #fff;
    font-size: 50px;
}

.bg-back {
    background-color: #d6e4ea;
}

.cards {
    background-color: transparent !important;
    text-align: left !important;
}

.impact-data {
    color: white;
    font-size: 2rem;
    margin-bottom: 50px;
}

.top_row {
    font-weight: 700;
    color: #64566b;
    margin-bottom: 8px;
}

.bot_row {
    font-weight: 700;
    font-size: 2rem;
}

.row_botom {
    font-weight: 400;
    font-size: 17px;
}

.sportlight_rell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    color: #63556b;
    padding-bottom: 32px;
}

.whatwedo_section {
    background-color: #edeeef;
}

.what_do {
    margin-top: 0;
}

// mobile view 
@media (min-width:320px) and (max-width:494px) {
    .hero-section {
        position: absolute;
        top: 15vh;
        left: 5vh;
        font-size: 4px;
    }
    .hero-section h2 {
        font-size: 4vh;
    }
    .btns-donate1234{
        font-size: 9px;
        background-color: red;
    }
    .Home_header {
        background-image: url("../../src/Feed-Components/assets/header.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 51vh;
        background-size: 100% 100%;
    }
    .our-partners {
        height: 20vh;
    }
    .map {
        margin: 0;
    }

    .mainsub_header{
        padding: 2vh 5vh;
    }
    .main123 {
        width: 100%;
        margin-top: 2vh;
    }
}

.test_designation{
    font-weight: 700;
}
.test_location{
    font-style: italic;
    font-size: small;
}
.text_name{
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #4f4f4f !important;
} 
.tooltip_but{
  height: 4vh;
 margin-right:5px; 
}

.dis_button{
    text-decoration: none !important;
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width:495px) and (max-width:768px) {
    .our-partners {
        height: 35vh;
    }
    .map {
        margin: 0;
    }
}
@media screen and (max-width:320px) {
    .our-partners img {
        width: 100%;
    }  
}
@media screen and (max-width:"333px") {
    .tooltip_but{
        height: 3vh;
            margin-right: 5px;
            margin-top: -85px;
            margin-right: -5px;  
    } 
}
@media screen and (max-width:"503px") {
    .but_alldata1{
        margin-top:9px!important;
        flex-direction: column;
    }
}
    
@media screen and (max-width:"503px") {
    .but_alldata2{
        margin-top:9px!important;
        flex-direction: column;
    }
    
}
@media screen and (max-width:"503px") {
    .but_alldata3{
        margin-top: 9px !important;
        padding-left: 5px !important;
        flex-direction: column;
    }   
}

// testimonial:- 
.testimonial_main{
    font-size: 20px !important;
    font-weight: 900 !important;
}
.test_recipt{
    font-size: 26px;
    font-weight: 500;
    margin-top: 17px;
}
.testimonial_header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 16px;
}
.test_txt{
    font-size: 20px;
    font-weight: 400;
}
.carousel{
    height: 50vh;
    display: flex;
    align-items: center;
}
.slider-content{
    flex-direction: column !important;
}
@media (min-width:320px) and (max-width:553px) {
    .alldata{
        flex-direction: column;
        row-gap: 15px;
        display: flex;
        justify-content:center;
        align-items:center;
 }
 .btns-donate1234{
    font-size: 13px;
    font-weight: 700;
 }
 .tooltip_but{
    height:2vh !important;
 }
 .but_alldata1,.but_alldata2,.but_alldata3{
     width: 60vw !important;
 }
 .test_txt{
    font-size: 14px;
    font-weight: 400;
}
.testimonial_header i{
    font-size: 12px;
}
.carousel{
    height:55vh;
}
}





    









